<template>
  <form class="login form">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Аутентификация</p>
        <button type="button" class="delete" @click="close()" />
      </header>
      <section class="modal-card-body">
        <b-field label="Имя пользователя:">
          <b-input
            type="text"
            v-model="username"
            placeholder="Имя пользователя"
            required
          >
          </b-input>
        </b-field>
        <b-field label="Пароль">
          <b-input
            type="password"
            v-model="password"
            password-reveal
            placeholder="Пароль"
            required
          >
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Закрыть" @click="close()" />
        <b-button
          label="Вход"
          @click.prevent="authenticate"
          type="is-primary"
        />
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "GBZAuthModal",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    authenticate() {
      const creds = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("auth/login", creds);
      this.$emit("close");
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
