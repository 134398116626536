import axiosInstance from "./api";
/*import crossfilter from "crossfilter2";
import Utilities from "./utils";*/
// eslint-disable-next-line no-unused-vars
//import { summaryConfigs, defaulChartColors } from "./summary-configs";

class SummaryService {
  getData(summaryName, filters) {
    let params = {};
    if (Object.keys(filters).length != 0) {
      params = {
        summaryName: summaryName,
        filters: JSON.stringify(filters),
      };
    } else {
      params = {
        summaryName: summaryName,
      };
    }
    return axiosInstance
      .get("/api/summaries", {
        params: params,
      })
      .then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error.data);
        }
      );
  }
  /*getFilterOptions(filters) {
    let filtersArray = [];
    let results = {};
    for (let fil of filters) {
      filtersArray.push(fil.field);
      results[fil.field] = {
        label: fil.label,
        options: [],
      };
    }
    return axiosInstance
      .get("/api/ref_filter_options", {
        params: {
          filters: JSON.stringify(filtersArray),
        },
      })
      .then(
        (response) => {
          for (let field in response.data) {
            results[field].options = response.data[field];
          }
          return Promise.resolve(results);
        },
        (error) => {
          return Promise.reject(error.data);
        }
      );
  }
  transformData(summaryName, data) {
    let structureConfig = summaryConfigs[summaryName].structureConfig;
    let rows = structureConfig.rows;
    let values = structureConfig.values;
    //let columns = configs.structureConfig.columns;

    //Выделение уникальных названий из поля, соответствующего наименее общей группе
    //let columnHeaders = this.getUniqueFieldValues(data, columns.field).sort();
    let ret = [];
    let groups = rows.slice(0, -1).map((row) => row.field);
    //Определяются уникальные значения групп по полученным данным
    //В prepData хранятся данные, сгруппированные по всем полям из rows. Значения наименее общего поля используются в качестве значений на оси категорий диаграмм
    ret = Utilities.groupByManyFields(data, groups);
    let columns = structureConfig.columns;
    if (columns !== undefined) {
      let otherValues = values.map((value) => value.field);
      ret = this.summaryData(ret, {
        row: rows[rows.length - 1].field,
        column: columns[0].field,
        value: columns[0].value.field,
        otherValues: otherValues,
      });
    }
    return ret;
  }
  //оставлять тотал на бэке или рассчитывать его здесь?
  summaryData(data, options) {
    if (Array.isArray(data)) {
      let ndx = crossfilter(data);
      let summaryCol = options.column;
      let summaryVal = options.value;
      let summaryRow = options.row;
      //let otherValues = options.otherValues;

      let out = [];

      let summaryRowDimension = ndx.dimension((d) => {
        return d[summaryRow];
      });

      let summaryColDimension = ndx.dimension((d) => {
        return d[summaryCol];
      });

      let totalBysummaryRow = summaryRowDimension.group().reduceSum((d) => {
        return d[summaryVal];
      });

      let allRecs = totalBysummaryRow.all();
      allRecs.forEach((rec) => {
        summaryRowDimension.filter();
        summaryRowDimension.filter(rec.key);
        let totalBysummaryCol = summaryColDimension.group().reduceSum((d) => {
          return d[summaryVal];
        });
        let data = totalBysummaryCol.all();
        let newRec = {};
        newRec[summaryRow] = rec.key;
        let doc = {};
        data.forEach((d) => {
          doc[d.key] = Math.round(d.value * 1000) / 1000;
        });
        newRec[summaryCol] = doc;
        out.push(newRec);
      });
      return out;
    } else {
      let ret = {};
      for (let group in data) {
        ret[group] = this.summaryData(data[group], options);
      }
      return ret;
    }
  }*/
}

export default new SummaryService();
