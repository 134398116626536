import TableService from "../services/table.service";
import { tableConfigs, generalFilters } from "../services/table-configs";

const defaultStatePerTable = {
  title: "",
  data: [],
  columns: [],
  curPage: 1,
  perPage: 10,
  total: null,
  filterDefs: [],
};

export const table = {
  namespaced: true,
  state: {
    tableConfigs: {
      default: defaultStatePerTable,
    },
  },
  actions: {
    loadData({ commit, rootState, state }, { tableID, params }) {
      commit("setPageState", {
        tableID: tableID,
        curPage: params.curPage,
        perPage: params.perPage,
      });

      let curFilterDefs = state.tableConfigs[tableID].filterDefs;
      let filters = {};
      for (let field of state.tableConfigs[tableID].filterDefs.map(
        (el) => el.field
      )) {
        if (rootState.filter.selectedOptions[field]) {
          filters[field] = rootState.filter.selectedOptions[field];
        }
      }
      commit("setFilterDefinitions", {
        tableID: tableID,
        filterDefinitions: curFilterDefs,
      });
      let newParams = params;
      newParams.filters = filters;
      return TableService.getData(tableID, newParams).then(
        (results) => {
          commit("setData", {
            tableID: tableID,
            data: results.results,
            total: results.count,
          });
          return Promise.resolve("Data is loaded");
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    formConfigs({ commit, rootState }, tableID) {
      let newTableConfigs = defaultStatePerTable;
      newTableConfigs.title = tableConfigs[tableID].title;
      commit("setNewTable", {
        tableID: tableID,
        configs: newTableConfigs,
      });
      let columns = tableConfigs[tableID].columns;
      commit("setColumns", {
        tableID: tableID,
        columns: columns,
      });

      let curFilterDefs =
        tableConfigs[tableID].uniqueFilters.concat(generalFilters);
      let filters = {};
      for (let field of curFilterDefs.map((el) => el.field)) {
        if (rootState.filter.selectedOptions[field]) {
          filters[field] = rootState.filter.selectedOptions[field];
        }
      }
      commit("setFilterDefinitions", {
        tableID: tableID,
        filterDefinitions: curFilterDefs,
      });
      let newParams = {};
      newParams.filters = filters;
      return TableService.getData(tableID, newParams).then(
        (results) => {
          commit("setData", {
            tableID: tableID,
            data: results.results,
            total: results.count,
          });
          return Promise.resolve("Table formed");
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setNewTable(state, payload) {
      state.tableConfigs[payload.tableID] = payload.configs;
    },
    setFilterDefinitions(state, payload) {
      state.tableConfigs[payload.tableID].filterDefs =
        payload.filterDefinitions;
    },
    setData(state, payload) {
      state.tableConfigs[payload.tableID].data = payload.data;
      state.tableConfigs[payload.tableID].total = payload.total;
    },
    setColumns(state, payload) {
      state.tableConfigs[payload.tableID].columns = payload.columns;
    },
    setPageState(state, payload) {
      state.tableConfigs[payload.tableID].curPage = payload.curPage;
      state.tableConfigs[payload.tableID].perPage = payload.perPage;
    },
  },
};
