import axiosInstance from "./api";

class TableService {
  getData(tableName, params) {
    return axiosInstance
      .get("/api/main_".concat(tableName), {
        params: {
          ...(Object.prototype.hasOwnProperty.call(params, "filters") && {
            filters: JSON.stringify(params.filters),
          }),
          ...(Object.prototype.hasOwnProperty.call(params, "orderBy") && {
            order_by: JSON.stringify(params.orderBy),
          }),
          page_num: params.curPage,
          per_page: params.perPage,
        },
      })
      .then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

export default new TableService();
