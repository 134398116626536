import SummaryService from "../services/summary.service";
import SummaryConstructorService from "../services/summary-output.service";
import { summaryConfigs, generalFilters } from "../services/summary-configs";

const moduleStates = {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  READY: 3,
};

const defaultState = {
  summaryName: "",
  summaryData: {},
  summaryTitle: "",
  summaryParams: [],
  summaryGroups: {},
  summaryFilterDefinitions: [],
};

export const summary = {
  namespaced: true,
  state: {
    summaryName: defaultState.summaryName,
    summaryData: defaultState.summaryData,
    summaryTitle: defaultState.summaryTitle,
    summaryParams: defaultState.summaryParams,
    summaryGroups: defaultState.summaryGroups,
    summaryFilterDefinitions: defaultState.summaryFilterDefinitions,
    moduleState: moduleStates.INIT,
  },
  actions: {
    setSummary({ commit }, summaryName) {
      commit("setModuleState", moduleStates.LOADING);
      commit("clearSummary");
      let curFilterDefs =
        summaryConfigs[summaryName].structureConfig.uniqueFilters.concat(
          generalFilters
        );
      commit("setSummary", {
        name: summaryName,
        title: summaryConfigs[summaryName].title,
        filters: curFilterDefs,
      });
    },
    getData({ commit, state, rootState }) {
      commit("setModuleState", moduleStates.LOADING);
      let filters = {};
      for (let field of state.summaryFilterDefinitions.map((el) => el.field)) {
        if (rootState.filter.selectedOptions[field]) {
          filters[field] = rootState.filter.selectedOptions[field];
        }
      }
      return SummaryService.getData(state.summaryName, filters).then(
        (data) => {
          let temp = SummaryConstructorService.formSummaryParams(
            state.summaryName,
            data
          );
          commit("setSummaryData", {
            data: data,
            params: temp.summaryParams,
            groups: temp.summaryGroups,
          });
          return Promise.resolve("It's OK!");
        },
        (error) => {
          commit("setModuleState", moduleStates.ERROR);
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setModuleState(state, moduleState) {
      state.moduleState = moduleState;
    },
    setSummary(state, params) {
      state.summaryName = params.name;
      state.summaryTitle = params.title;
      state.summaryFilterDefinitions = params.filters;
      state.moduleState = moduleStates.READY;
    },
    setSummaryData(state, params) {
      state.summaryData = params.data;
      state.summaryParams = params.params;
      state.summaryGroups = params.groups;
      state.moduleState = moduleStates.READY;
    },
    /*setDefaultState(state) {
      state.summaryName = defaultState.summaryName;
      state.summaryData = defaultState.summaryData;
      state.summaryTitle = defaultState.summaryTitle;
      state.summaryParams = defaultState.summaryParams;
      state.summaryGroups = defaultState.summaryGroups;
    },*/
    clearSummary(state) {
      state.summaryData = defaultState.summaryData;
      state.summaryParams = defaultState.summaryParams;
      state.summaryGroups = defaultState.summaryGroups;
    },
  },
};
