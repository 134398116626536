/*Объект pivotConfigs включает конфигурации соответствующих сводных данных
Каждое свойство объекта соответствует тем или иным правилам, по которым происходит свод данных
Свойства включают в себя объекты с общей структурой:
title - название результатов свода, использующееся для вывода в меню и в качестве заголовков
structureConfig - включает в себя группы полей, по которым производится свод данных
Группы включают в себя объекты, содержащие, как минимум, свойства field (название поля, использующееся в коде) и label (название поля для отображения на странице).
При описании правил свода используются следующие группы:
  filters - поля со значениями категорий, по которым на сервере происходит изначальная фильтрация данных
  rows - поля со значениями категорий, по которым производится группирование отфильтрованных данных. Поля располагаются в порядке убывания общности группы. Например, при порядке ["ед. изм", "год"] группы "ед. изм" будут включать в себя строки, соответствующие той или иной группе "год"
  values - поля, значения которых содержат информативную часть свода. Названия отражают тип производимой аггрегации и название поля, по которому эта аггрегация производится
chartConfigs - параметры конфигурации диаграмм для ChartJS*/

/* В данном файле описана конфигурация для следующих сводов (название и имя в коде):
    "Прирост/убыль запасов"	'reserves_inc_dec'
    "Движение запасов"	'reserves_movement'
    "Распределение по округам"	'gbz_by_fo'
    "Структура по степени разведанности"	'exploring_degree_dynamic'
    "Распределение по округам"	'reserves_in_fo_by_year'
    "Динамика запасов по ФО"	'reserve_dynamic_by_fo'
    "Динамика запасов по субъектам"	'reserve_dynamic_by_subj'
    "Динамика добычи по РФ"	'mining_dynamic_by_RF'
    "Динамика добычи по ФО"	'mining_dynamic_by_fo'
    "Воспроизводство ТПИ"	'repr_solid_mineral'

  

*/

/*const defaulChartColors = [
  "#5B9BD5",
  "#ED7D31",
  "#A5A5A5",
  "#FFC000",
  "#4472C4",
  "#70AD47",
  "#F1A78A",
  "#a61036",
  "#5b5266",
  "#f7f9fb",
  "#2f1551",
  "#511519",
  "#375115",
  "#6ea22a",
  "#2a6ea2",
  "#9ea22a",
  "#a2762a",
  "#462aa2",
  "#2aa246",
  "#a22a86",
];*/

const generalFilters = [
  {
    field: "orecomp",
    label: "Руда/компонент",
    view: "seq",
  },
  {
    field: "gbz",
    label: "ГБЗ",
    view: "nested",
  },
  {
    field: "fo",
    label: "Федеральный округ",
    view: "nested",
  },
  {
    field: "gentype",
    label: "Ген. тип",
    view: "seq",
  },
  {
    field: "fund",
    label: "Фонд недр",
    view: "nested",
  },
  {
    field: "nas",
    label: "ВНЗ",
    view: "seq",
  },
];

const summaryConfigs = {
  reserves_inc_dec: {
    title: "Прирост/убыль запасов",
    structureConfig: {
      uniqueFilters: [
        {
          field: "year",
          label: "Год",
          view: "numbers",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "year",
          label: "Год",
        },
      ],
      values: [
        {
          field: "total_explore",
          label: "Разведано",
          chartBGColor: "#0e4ecc",
          chartBorderColor: "#0e4ecc",
          type: "column",
          stack: "column",
        },
        {
          field: "total_reeval",
          label: "Переоценка",
          chartBGColor: "#b0b315",
          chartBorderColor: "#b0b315",
          type: "column",
          stack: "column",
        },
        {
          field: "total_writeoff",
          label: "Списано",
          chartBGColor: "#757575",
          chartBorderColor: "#757575",
          type: "column",
          stack: "column",
        },
        {
          field: "total_other",
          label: "Другие причины",
          chartBGColor: "#b58724",
          chartBorderColor: "#b58724",
          type: "column",
          stack: "column",
        },
        {
          field: "total_mining",
          label: "Добыча",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          type: "spline",
        },
        {
          field: "total_mining_loss",
          label: "Потери",
          chartBGColor: "#bf2b11",
          chartBorderColor: "#bf2b11",
          type: "spline",
        },
      ],
    },
    chartConfigs: {
      chart: {
        zoomType: "xy",
      },
      xAxis: {
        categories: [],
        title: {
          text: "Год",
        },
      },
      yAxis: {
        title: {
          text: "Изменение запасов кат. A+B+C1",
        },
      },
      credits: {
        enabled: false,
      },
      /*tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },*/
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  reserves_movement: {
    title: "Движение запасов",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "state_date",
          label: "По состоянию на",
        },
      ],
      values: [
        {
          field: "total_abc1",
          label: "ABC1",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          type: "column",
        },
        {
          field: "total_c2",
          label: "C2",
          chartBGColor: "#bf2b11",
          chartBorderColor: "#bf2b11",
          type: "column",
        },
      ],
    },
    chartConfigs: {
      chart: {
        zoomType: "xy",
      },
      xAxis: {
        categories: [],
        title: {
          text: "По состоянию на",
        },
      },
      yAxis: {
        title: {
          text: "Запасы",
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {},
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  gbz_by_fo: {
    title: "Распределение по округам",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "fo",
          label: "Федеральный округ",
        },
      ],
      values: [
        {
          field: "total_abc1",
          label: "ABC1",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          type: "column",
        },
        {
          field: "total_c2",
          label: "C2",
          chartBGColor: "#bf2b11",
          chartBorderColor: "#bf2b11",
          type: "column",
        },
      ],
    },
    chartConfigs: {
      chart: {
        zoomType: "xy",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "Федеральный округ",
        },
      },
      yAxis: {
        title: {
          text: "Запасы",
        },
      },
      plotOptions: {},
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  exploring_degree_dynamic: {
    title: "Структура по степени разведанности",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "state_date",
          label: "По состоянию на",
        },
      ],
      columns: [
        {
          field: "degree",
          label: "Степень освоения",
          value: {
            field: "total_abc1c2_indegree",
            label: "ABC1+C2 (доля степени за год)",
            type: "bar",
          },
          chartType: "bar",
          chartStackGroup: "total_abc1c2_indegree",
        },
      ],
      totals: {
        horizontal: {
          label: "ABC1+C2 (за год всего)",
          field: "total",
        },
        vertical: {
          label: "Всего за период",
        },
      },
    },
    chartConfigs: {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "По состоянию на",
        },
      },
      yAxis: {
        title: {
          text: "Запасы кат. A+B+C1+C2",
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "percent",
          dataLabels: {
            enabled: true,
          },
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  reserves_in_fo_by_year: {
    title: "Структура запасов по ФО",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "state_date",
          label: "По состоянию на",
        },
      ],
      columns: [
        {
          field: "fo",
          label: "Федеральный округ",
          value: {
            field: "total_abc1c2_in_fo",
            label: "ABC1+C2 (доля округа за год)",
            type: "bar",
          },
          chartStackGroup: "total_abc1c2_in_fo",
        },
      ],
      totals: {
        horizontal: {
          label: "ABC1+C2 (за год всего)",
          field: "total",
        },
        vertical: {
          label: "Всего по ФО",
        },
      },
    },
    chartConfigs: {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "По состоянию на",
        },
      },
      yAxis: {
        title: {
          text: "ABC1+C2",
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "percent",
          dataLabels: {
            enabled: true,
          },
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  reserve_dynamic_by_fo: {
    title: "Динамика запасов по ФО",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "fo",
          label: "Федеральный округ",
        },
      ],
      columns: [
        {
          field: "state_date",
          label: "По состоянию на",
          value: {
            field: "total_abc1c2_in_fo",
            label: "ABC1+C2 (доля округа за год)",
            type: "column",
          },
          chartType: "column",
          chartStackGroup: "total_abc1c2_in_fo",
        },
      ],
      totals: {
        horizontal: {
          label: "ABC1+C2 (по ФО всего)",
          field: "total",
        },
        vertical: {
          label: "За год всего",
        },
      },
    },
    chartConfigs: {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "Федеральный округ",
        },
      },
      yAxis: {
        title: {
          text: "ABC1+C2",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  reserve_dynamic_by_subj: {
    title: "Динамика запасов по субъектам",
    structureConfig: {
      uniqueFilters: [
        {
          field: "state_date",
          label: "По состоянию на",
          view: "dates",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "subj",
          label: "Субъект",
        },
      ],
      columns: [
        {
          field: "state_date",
          label: "По состоянию на",
          value: {
            field: "total_abc1c2_in_subj",
            label: "ABC1+C2 (доля субъекта за год)",
            type: "column",
          },
          chartStackGroup: "total_abc1c2_in_subj",
        },
      ],
      totals: {
        horizontal: {
          label: "ABC1+C2 (по субъекту всего)",
          field: "total",
        },
        vertical: {
          label: "Всего за период",
        },
      },
    },
    chartConfigs: {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "Субъект",
        },
      },
      yAxis: {
        title: {
          text: "ABC1+C2",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  mining_dynamic_by_RF: {
    title: "Динамика добычи по РФ",
    structureConfig: {
      uniqueFilters: [
        {
          field: "year",
          label: "Год",
          view: "numbers",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "year",
          label: "Год",
        },
      ],
      values: [
        {
          field: "total_mining",
          label: "Добыча",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          //chartType: "column",
        },
        {
          field: "total_mining_loss",
          label: "Потери",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          //chartType: "column",
        },
      ],
    },
    chartConfigs: {
      chart: {
        type: "area",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "Год",
        },
      },
      yAxis: {
        title: {
          text: "Изменение запасов кат. A+B+C1",
        },
        reversedStacks: false,
      },
      plotOptions: {
        area: {
          stacking: "normal",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  mining_dynamic_by_fo: {
    title: "Динамика добычи по ФО",
    structureConfig: {
      uniqueFilters: [
        {
          field: "year",
          label: "Год",
          view: "numbers",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "year",
          label: "Год",
        },
      ],
      columns: [
        {
          field: "fo",
          label: "Федеральный округ",
          value: {
            field: "total_mining_in_fo",
            label: "Добыча в ФО",
            //type: "column",
          },
          chartType: "column",
          chartStackGroup: "total_abc1c2_in_fo",
        },
      ],
      totals: {
        horizontal: {
          label: "ABC1+C2 (за год всего)",
          field: "total",
        },
        vertical: {
          label: "Всего по ФО",
        },
      },
    },
    chartConfigs: {
      chart: {
        type: "area",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "Год",
        },
      },
      yAxis: {
        title: {
          text: "Изменение запасов кат. A+B+C1",
        },
        reversedStacks: false,
      },
      plotOptions: {
        area: {
          stacking: "normal",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, {unit}",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, {unit}",
      },
    },
  },
  repr_solid_mineral: {
    title: "Воспроизводство ТПИ",
    structureConfig: {
      uniqueFilters: [
        {
          field: "year",
          label: "Год",
          view: "numbers",
        },
      ],
      rows: [
        {
          field: "unit",
          label: "Ед. изм.",
        },
        {
          field: "gbz",
          label: "ГБЗ",
        },
      ],
      values: [
        {
          field: "solid_mineral_repr",
          label: "Воспроизводство ТПИ",
          chartBGColor: "#2e822f",
          chartBorderColor: "#2e822f",
          type: "bar",
        },
      ],
    },
    chartConfigs: {
      chart: {
        type: "bar",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: "ГБЗ",
        },
      },
      yAxis: {
        title: {
          text: "Воспроизводство ТПИ",
        },
      },
      groupsToYTitle: {
        fields: ["unit"],
        format: "{yTitle}, % (рассчитано по {unit})",
      },
      groupsToChartTitle: {
        fields: ["unit"],
        format: "{chartTitle}, % (рассчитано по {unit})",
      },
    },
  },
};

export { summaryConfigs, generalFilters };
