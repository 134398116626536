<template>
  <div>
    <div v-if="this.$store.state.auth.status.loggedIn">
      <GBZFilters
        v-show="pagesWithFilters.includes(this.$router.history.current.path)"
      />
      <router-view></router-view>
    </div>
    <div v-else>
      <GBZNotPermited />
    </div>
  </div>
</template>

<script>
import GBZNotPermited from "@/components/GBZContent/GBZNotPermited.vue";
import GBZFilters from "@/components/GBZContent/GBZFilters.vue";

export default {
  name: "GBZContent",
  components: { GBZNotPermited, GBZFilters },
  data() {
    return {
      pagesWithFilters: ["/changes", "/states", "/summary"],
    };
  },
};
</script>
