import axios from "axios";

const instance = axios.create({
  //baseURL: "http://127.0.0.1:8000",
  baseURL: "https://gbz-back.data-geo.ru",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.defaults.xsrfCookieName = "csrftoken";
instance.defaults.withCredentials = true;

export default instance;
