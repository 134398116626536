const generalFilters = [
  {
    field: "orecomp",
    label: "Руда/компонент",
    view: "seq",
  },
  {
    field: "gbz",
    label: "ГБЗ",
    view: "nested",
  },
  /*{
    field: "mineral",
    label: "ПИ",
    view: "seq",
  },*/
  {
    field: "fo",
    label: "Федеральный округ",
    view: "nested",
  },
  /*{
    field: "subj",
    label: "Субъект",
    view: "seq",
  },*/
  {
    field: "gentype",
    label: "Ген. тип",
    view: "seq",
  },
  {
    field: "fund",
    label: "Фонд недр",
    view: "nested",
  },
  /*{
    field: "degree",
    label: "Степень освоения",
    view: "seq",
  },*/
  {
    field: "nas",
    label: "ВНЗ",
    view: "seq",
  },
];

const tableConfigs = {
  state: {
    title: "Состояния ГБЗ",
    uniqueFilters: [
      {
        field: "state_date",
        label: "По состоянию на",
        view: "dates",
      },
    ],
    columns: [
      {
        format: "{orecomp} {mineral} ({gbz}), {unit} ({state_date})",
        field: ["orecomp", "mineral", "gbz", "unit", "state_date"],
        label: "Тип ГБЗ",
      },
      {
        field: ["fo"],
        label: "Федеральный округ",
        sortable: true,
      },
      {
        field: ["subj"],
        label: "Субъект",
        sortable: true,
      },
      {
        field: ["gentype"],
        label: "Ген. тип",
        sortable: true,
      },
      {
        field: ["fund"],
        label: "Фонд недр",
        sortable: true,
      },
      {
        field: ["degree"],
        label: "Степень освоения",
        sortable: true,
      },
      {
        field: ["nas"],
        label: "Временно-неактивные запасы",
        sortable: true,
      },
      {
        field: ["workoff"],
        label: "Вид обработки",
        sortable: true,
      },
      {
        field: ["complexity"],
        label: "Комплексность",
        sortable: true,
      },
      {
        field: ["stratification"],
        label: "Вид залегания",
        sortable: true,
      },
      {
        field: ["oretype"],
        label: "Тип руды",
        sortable: true,
      },
      {
        field: ["oresubtype"],
        label: "Подтип руды",
        sortable: true,
      },
      {
        field: ["oregrade"],
        label: "Сорт руды",
        sortable: true,
      },
      {
        field: ["deposit_count"],
        label: "Количество месторождений",
        sortable: true,
      },
      {
        field: ["ab"],
        label: "A+B",
        sortable: true,
      },
      {
        field: ["abc1"],
        label: "A+B+C1",
        sortable: true,
      },
      {
        field: ["c2"],
        label: "C2",
        sortable: true,
      },
      {
        field: ["zbs"],
        label: "Забалансовые",
        sortable: true,
      },
      {
        field: ["approved"],
        label: "Утвержденные ГКЗ (ТКЗ) А+В+С1 (остаток)",
        sortable: true,
      },
    ],
    /*filters: [
      "orecomp",
      "state_date",
      "gbz",
      "mineral",
      "unit",
      "fo",
      "subj",
      "gentype",
      "fund",
      "degree",
      "nas",
    ],*/
  },
  change: {
    title: "Изменения ГБЗ",
    uniqueFilters: [
      {
        field: "year",
        label: "Год",
        view: "numbers",
      },
    ],
    columns: [
      {
        format: "{orecomp} {mineral} ({gbz}), {unit} ({year})",
        field: ["orecomp", "mineral", "gbz", "unit", "year"],
        label: "Тип ГБЗ",
        sortable: true,
      },
      {
        field: ["fo"],
        label: "Федеральный округ",
        sortable: true,
      },
      {
        field: ["subj"],
        label: "Субъект",
        sortable: true,
      },
      {
        field: ["gentype"],
        label: "Ген. тип",
        sortable: true,
      },
      {
        field: ["fund"],
        label: "Фонд недр",
        sortable: true,
      },
      {
        field: ["degree"],
        label: "Степень освоения",
        sortable: true,
      },
      {
        field: ["nas"],
        label: "Временно-неактивные запасы",
        sortable: true,
      },
      {
        field: ["workoff"],
        label: "Вид обработки",
        sortable: true,
      },
      {
        field: ["complexity"],
        label: "Комплексность",
        sortable: true,
      },
      {
        field: ["stratification"],
        label: "Вид залегания",
        sortable: true,
      },
      {
        field: ["oretype"],
        label: "Тип руды",
        sortable: true,
      },
      {
        field: ["oresubtype"],
        label: "Подтип руды",
        sortable: true,
      },
      {
        field: ["oregrade"],
        label: "Сорт руды",
        sortable: true,
      },
      {
        field: ["deposit_count"],
        label: "Количество месторождений",
        sortable: true,
      },
      {
        field: ["mining"],
        label: "Добыча",
        sortable: true,
      },
      {
        field: ["mining_loss"],
        label: "Потери",
        sortable: true,
      },
      {
        field: ["explore"],
        label: "Разведка",
        sortable: true,
      },
      {
        field: ["reeval"],
        label: "Переоценка",
        sortable: true,
      },
      {
        field: ["writeoff"],
        label: "Списано",
        sortable: true,
      },
      {
        field: ["other"],
        label: "Другое",
        sortable: true,
      },
    ],
    /*filters: [
      "orecomp",
      "year",
      "gbz",
      "mineral",
      "unit",
      "fo",
      "subj",
      "gentype",
      "fund",
      "degree",
      "nas",
    ],*/
  },
};

export { tableConfigs, generalFilters };
