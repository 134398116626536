<template>
  <div>
    <div v-if="this.$store.state.auth.status.loggedIn">
      <span class="navbar-text">
        Пользователь: {{ this.$store.state.auth.fullname }}
        <button @click.prevent="logout" class="button primary" type="submit">
          Выход
        </button>
      </span>
    </div>
    <div v-else>
      <span class="navbar-text">
        Вы не авторизованы
        <button @click="showGBZAuthModal" class="button primary" type="submit">
          Войти
        </button>
        <b-modal
          v-model="isGBZAuthModalVisible"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Аутентификация"
          close-button-aria-label="Закрыть"
          aria-modal
        >
          <GBZAuthModal @close="closeGBZAuthModal()" />
        </b-modal>
      </span>
    </div>
  </div>
</template>

<script>
import GBZAuthModal from "@/components/GBZHeader/GBZAuthentication/GBZAuthModal.vue";

export default {
  name: "GBZAuthenticationPanel",
  components: { GBZAuthModal },
  data() {
    return {
      isGBZAuthModalVisible: false,
    };
  },
  methods: {
    showGBZAuthModal() {
      this.isGBZAuthModalVisible = true;
    },
    closeGBZAuthModal() {
      this.isGBZAuthModalVisible = false;
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  created() {
    this.$store
      .dispatch("auth/checkAuthStatus")
      .then(() => {
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  /*created() {
    this.$store.getters("auth/getLoginStatus");
    this.$store.dispatch("auth/checkAuthStatus");
  },*/
};
</script>
