//import summaryConfigs from "../services/summary-configs";
import Utilities from "./utils";
// eslint-disable-next-line no-unused-vars
import { summaryConfigs, defaulChartColors } from "./summary-configs";

class SummaryConstructorService {
  formSummaryParams(summaryName, data) {
    //Выделение уникальных названий из поля, соответствующего наименее общей группе
    //let columnHeaders = this.getUniqueFieldValues(data, columns.field).sort();
    let summaryGroups = {};
    let rows = summaryConfigs[summaryName].structureConfig.rows;
    let groupingFields = rows.slice(0, -1);
    //Определяются уникальные значения групп по полученным данным
    if (groupingFields.length !== 0) {
      for (let i in groupingFields) {
        let depth = Number(i);
        summaryGroups[groupingFields[i].field] = {
          label: groupingFields[i].label,
          options: Utilities.getUniqueGroupValues(data, depth).sort(),
        };
      }
    } else {
      let oneGroupName = Object.keys(data)[0];
      groupingFields.push({
        field: oneGroupName,
        label: oneGroupName,
      });
      summaryGroups[oneGroupName] = {
        label: oneGroupName,
        options: [oneGroupName],
      };
    }
    //В prepData хранятся данные, сгруппированные по всем полям из rows. Значения наименее общего поля используются в качестве значений на оси категорий диаграмм
    //chartSetups - массив объектов, каждый из которых содержит конфигурацию диаграмм
    let summaryParams = this.createSummaryParams(data, {
      groups: groupingFields,
      groupsMembership: {},
      summaryName: summaryName,
    });
    return { summaryParams: summaryParams, summaryGroups: summaryGroups };
  }

  createSeriesSetAndTableColumns(data, params) {
    let tableColumns = [];
    tableColumns.push({
      label: params.rows[params.rows.length - 1].label,
      field: params.rows[params.rows.length - 1].field,
      sortable: true,
    });
    let seriesSet = [];
    if (params.columns) {
      for (const column of params.columns) {
        let excludedFields = [].concat(params.rows.map((x) => x.field));
        if (params.values) {
          excludedFields = excludedFields.concat(
            params.values.map((x) => x.field)
          );
        }
        if (params.horTotalsField) {
          excludedFields.push(params.horTotalsField);
        }
        let colHeaders = Object.keys(data[0]).filter(
          (field) => !excludedFields.includes(field)
        );
        for (let header of colHeaders) {
          tableColumns.push({
            label: header,
            field: header,
            sortable: true,
          });
          seriesSet.push({
            name: header,
            data: data.map((y) => y[header]),
            type: column.value.type,
            //stack: params.columns.value.stack,
          });
        }
      }
    }
    if (params.values) {
      for (const value of params.values) {
        tableColumns.push({
          label: value.label,
          field: value.field,
          sortable: true,
          //stack: value.stack,
        });
        seriesSet.push({
          name: value.label,
          data: data.map((y) => y[value.field]),
          type: value.type,
          //stack: value.stack,
        });
      }
    }
    return { seriesSet: seriesSet, tableColumns: tableColumns };
  }

  createSummaryParams(data, params) {
    let rows = summaryConfigs[params.summaryName].structureConfig.rows;
    let values = summaryConfigs[params.summaryName].structureConfig.values;
    let columns = summaryConfigs[params.summaryName].structureConfig.columns;
    let totals = summaryConfigs[params.summaryName].structureConfig.totals;

    if (Array.isArray(data)) {
      let xField = rows[rows.length - 1].field;
      let totalsValues = {};
      let horTotalsPresent = false;
      if (totals) {
        if (totals.vertical) {
          totalsValues["vertical"] = {
            label: totals.vertical.label,
            values: data.filter((y) => y[xField] === totals.vertical.label)[0],
          };
          data = data.filter((y) => y[xField] !== totals.vertical.label);
        }
        if (totals.horizontal) {
          let horTotals = [];
          horTotalsPresent = true;
          for (let row of data) {
            horTotals.push({
              [totals.horizontal.field]: row[totals.horizontal.field],
            });
          }
          totalsValues["horizontal"] = {
            label: totals.horizontal.label,
            field: totals.horizontal.field,
            values: horTotals,
          };
        }
      }
      let temp = this.createSeriesSetAndTableColumns(data, {
        rows: rows,
        columns: columns,
        values: values,
        horTotalsField: horTotalsPresent && totals.horizontal.field,
      });
      if (totals) {
        if (totals.horizontal) {
          temp.tableColumns.push({
            label: totals.horizontal.label,
            field: totals.horizontal.field,
            sortable: true,
            //stack: value.stack,
          });
        }
      }
      let categories = data.map((x) => x[xField]);
      let summaryParam = {
        groups: JSON.parse(JSON.stringify(params.groupsMembership)),
        chartOptions: JSON.parse(
          JSON.stringify(summaryConfigs[params.summaryName].chartConfigs)
        ),
        tableOptions: {
          data: data,
          columns: temp.tableColumns,
          totals: totalsValues,
        },
      };

      summaryParam.chartOptions.series = temp.seriesSet;
      summaryParam.chartOptions.xAxis.categories = categories;
      if (summaryConfigs[params.summaryName].chartConfigs.groupsToYTitle) {
        let valuesToPlace = params.groupsMembership;
        valuesToPlace.yTitle =
          summaryConfigs[params.summaryName].chartConfigs.yAxis.title.text;
        summaryParam.chartOptions.yAxis.title.text = Utilities.stringByTemplate(
          summaryConfigs[params.summaryName].chartConfigs.groupsToYTitle.format,
          valuesToPlace
        );
      }
      if (summaryConfigs[params.summaryName].chartConfigs.groupsToChartTitle) {
        let valuesToPlace = params.groupsMembership;
        valuesToPlace.chartTitle = summaryConfigs[params.summaryName].title;
        summaryParam.chartOptions.title = {
          text: Utilities.stringByTemplate(
            summaryConfigs[params.summaryName].chartConfigs.groupsToChartTitle
              .format,
            valuesToPlace
          ),
        };
      }
      return summaryParam;
    }
    let summaryParams = [];
    for (let group in data) {
      let groupsCopy = JSON.parse(JSON.stringify(params.groupsMembership));
      groupsCopy[params.groups[0].field] = group;
      summaryParams = summaryParams.concat(
        this.createSummaryParams(data[group], {
          groups: params.groups.slice(1),
          groupsMembership: groupsCopy,
          summaryName: params.summaryName,
        })
      );
    }
    return summaryParams;
  }
}

export default new SummaryConstructorService();
