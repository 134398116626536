import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { filter } from "./filter.module";
import { summary } from "./summary.module";
import { table } from "./table.module";
import { upload } from "./upload.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    filter,
    summary,
    table,
    upload,
  },
});
