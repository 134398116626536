import UploadService from "../services/upload.service";

const notResolvedStatuses = [
  "Starting",
  "Processing",
  "PushingRecords",
  "PartlyValidDataPendingForConfirmation",
];

const defaultState = {
  status: {
    name: "WaitingForUpload",
    status: "Ожидание загрузки",
  },
  stats: {
    uploadStarted: "",
    uploadEnded: "",
    errors: "",
    stats: "",
  },
  formData: null,
};

export const upload = {
  namespaced: true,
  state: {
    status: defaultState.status,
    stats: defaultState.stats,
    formData: null,
    //errors: defaultState.errors,
  },
  actions: {
    uploadFile({ commit, dispatch }, formData) {
      commit("setFormData", formData);
      return dispatch("auth/checkAuthStatus", {}, { root: true }).then(
        () => {
          return UploadService.uploadFile(formData).then(
            (uploadStatus) => {
              return Promise.resolve(uploadStatus);
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getErrorReport() {
      return UploadService.getErrorReport().then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    checkUploadProgress({ commit, state }) {
      return UploadService.getLastUploadSession().then(
        (session) => {
          let strToRet = "";
          if (state.status.name === session.status.name) {
            strToRet = "Status not changed";
          } else {
            strToRet = "Status changed";
          }
          commit("setUploadSession", session);
          return Promise.resolve(strToRet);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendAnswer({ commit }, statusToChange) {
      return UploadService.changeUploadSessionStatus(statusToChange).then(
        (session) => {
          commit("setUploadSession", session);
          return Promise.resolve(
            "Answer is sended. Current status is: " + session.status.name
          );
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getLastUploadSession({ commit }) {
      return UploadService.getLastUploadSession().then(
        (session) => {
          if (!notResolvedStatuses.includes(session.status.name)) {
            commit("clearUploadSession");
            return Promise.resolve("Ready to upload");
          } else {
            commit("setUploadSession", session);
            return Promise.resolve("Not resolved status is pending");
          }
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setUploadSession(state, session) {
      const tempStats = {
        uploadStarted: session.upload_started,
        uploadEnded: session.upload_ended,
        errors: session.errors,
        stats: session.stats,
      };
      state.status = {
        name: session.status.name,
        status: session.status.status,
      };
      state.stats = tempStats;
      //state.errors = session.errors;
    },
    setFormData(state, formData) {
      state.formData = formData;
    },
    clearUploadSession(state) {
      state.status = defaultState.status;
      state.stats = defaultState.stats;
      //state.errors = defaultState.errors;
    },
  },
  getters: {
    getFormData(state) {
      return state.formData;
    },
  },
};
