import axiosInstance from "./api";

const setup = (store) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== "/login/" &&
        err.response &&
        !originalConfig._retry
      ) {
        if (err.response.status === 401) {
          originalConfig._retry = false;
          try {
            await store.dispatch("auth/refreshJWT");
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
