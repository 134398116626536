<!-- eslint-disable prettier/prettier -->
<template>
  <b-navbar class="is-dark">
    <template #brand>
      <b-navbar-item tag="router-link" to="/">
        Сводный ГБЗ
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" to="/changes">
        Изменения ГБЗ
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/states">
        Состояния ГБЗ
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/summary">
        Сводные данные
      </b-navbar-item>
      <b-navbar-item tag="router-link" to="/upload">
        Загрузка данных
      </b-navbar-item>
      <!--<b-navbar-item>
        <router-link class="nav-link" to="/test">Тест</router-link>
      </b-navbar-item>-->
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <GBZAuthenticationPanel />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import GBZAuthenticationPanel from "@/components/GBZHeader/GBZAuthentication/GBZAuthenticationPanel.vue";

export default {
  components: { GBZAuthenticationPanel },
  name: "GBZHeader",
  /*data() {
    return {
      links: [
        {
          link: "",
          title: "",
          isActive: false,
        },
      ],
    };
  },*/
};
</script>
