import axiosInstance from "./api";

class FiltersService {
  getFilterOptions(filters) {
    return axiosInstance
      .get("/api/ref_filter_options", {
        params: {
          filters: JSON.stringify(filters),
        },
      })
      .then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

export default new FiltersService();
