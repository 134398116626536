import api from "./api";
import CookiesService from "../services/cookies.service";
//import getURL from "./api-map";

class AuthService {
  login(creds) {
    return api
      .post("/login/", {
        username: creds.username,
        password: creds.password,
      })
      .then((response) => {
        return Promise.resolve({
          status: response.data.status,
          username: CookiesService.getCookie("username"),
          fullname: response.data.fullname,
        });
      })
      .catch((error) => {
        return Promise.reject(error.data);
      });
  }
  logout() {
    return api
      .get("/logout/")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.data);
      });
  }
  refreshJWT() {
    return api
      .get("/refreshJWT/")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.data);
      });
  }
  checkAuthStatus() {
    return api
      .get("/check_auth_status/")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.data);
      });
  }
}

export default new AuthService();
