import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Сводный ГБЗ",
    component: () => import("@/views/GBZMain.vue"),
    meta: {
      title: "Сводный ГБЗ",
    },
  },
  {
    path: "/changes",
    name: "Изменения запасов",
    component: () => import("@/views/GBZChanges.vue"),
    meta: {
      title: "Изменения запасов",
    },
  },
  {
    path: "/states",
    name: "Состояния запасов",
    component: () => import("@/views/GBZStates.vue"),
    meta: {
      title: "Состояния запасов",
    },
  },
  {
    path: "/summary",
    name: "Сводные данные",
    component: () => import("@/views/GBZSummary.vue"),
    meta: {
      title: "Сводные данные",
    },
  },
  {
    path: "/upload",
    name: "Загрузка данных",
    component: () => import("@/views/GBZUpload.vue"),
    meta: {
      title: "Загрузка данных",
    },
  },
  /*{
    path: "/test",
    name: "Тест",
    component: () => import("@/views/GBZTest.vue"),
  },*/
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "is-active",
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
