import AuthService from "../services/auth.service";
import CookiesService from "../services/cookies.service";

export const auth = {
  namespaced: true,
  state: {
    status: { loggedIn: false },
    username: null,
    fullname: null,
  },
  actions: {
    checkAuthStatus({ commit }) {
      //let username = CookiesService.getCookie("username");
      return AuthService.checkAuthStatus().then((status) => {
        if (status.status == "Authenticated") {
          commit("loginSuccess", {
            username: status.username,
            fullname: status.fullname,
          });
          return Promise.resolve(status.status);
        } else {
          return AuthService.refreshJWT().then(
            (refresh_status) => {
              //const username = CookiesService.getCookie("username");
              commit("loginSuccess", {
                username: refresh_status.username,
                fullname: refresh_status.fullname,
              });
              return Promise.resolve(refresh_status.status);
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        }
      });
    },
    login({ commit }, creds) {
      return AuthService.login(creds).then(
        (loginStatus) => {
          commit("loginSuccess", {
            username: loginStatus.username,
            fullname: loginStatus.fullname,
          });
          return Promise.resolve(loginStatus.status);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      return AuthService.logout().then(
        (status) => {
          commit("logout");
          return Promise.resolve(status.status);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    refreshJWT({ commit }) {
      return AuthService.refreshJWT().then(
        (status) => {
          const username = CookiesService.getCookie("username");
          commit("loginSuccess", {
            username: username,
          });
          return Promise.resolve(status.status);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, payload) {
      state.status.loggedIn = true;
      state.username = payload.username;
      if (payload.fullname !== undefined) {
        state.fullname = payload.fullname;
      }
    },
    loginFail(state) {
      state.status.loggedIn = false;
      state.username = null;
      state.fullname = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.username = null;
      state.fullname = null;
    },
  },
  getters: {
    getLoginStatus(state) {
      return state.status.loggedIn, state.username, state.fullname;
    },
  },
};
