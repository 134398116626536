<template>
  <ul class="filter-list-nested" v-if="filterSet.nestedOptions === undefined">
    <li v-if="filterSet.type === `range`">
      <p>
        {{ filterOptions[selectedOptionsIndexesRange[0]] }} -
        {{ filterOptions[selectedOptionsIndexesRange[1]] }}
      </p>
      <b-slider
        v-model="selectedOptionsIndexesRange"
        :min="0"
        :max="filterOptions.length - 1"
        :custom-formatter="(val) => filterOptions[val]"
        ticks
        lazy
      >
      </b-slider>
    </li>
    <div v-else>
      <li
        class="filter-item"
        v-for="option in filterOptions"
        :key="filterSet.field + `.` + option"
      >
        <b-radio
          v-if="filterSet.type === `radio`"
          v-model="selectedOptions[0]"
          :native-value="option"
          size="is-small"
          @input="onOptionsChanged"
        >
          <div>
            {{ option }}
          </div>
        </b-radio>
        <b-checkbox
          v-if="filterSet.type === `checkbox`"
          v-model="selectedOptions"
          :native-value="option"
          size="is-small"
          @input="onOptionsChanged"
        >
          <div>
            {{ option }}
          </div>
        </b-checkbox>
      </li>
    </div>
  </ul>
  <ul class="filter-list" v-else>
    <li
      class="filter-item"
      v-for="(elProps, name) in collapsingElements"
      :key="filterSet.field + `.` + name"
    >
      <span>
        <b-icon @click.native="toggleOptions(name)" :icon="elProps.icon" />
        <b-checkbox
          v-model="selectedOptions"
          :native-value="name"
          :indeterminate="elProps.indeterminate"
          @input="changeNestedState(name)"
          size="is-small"
        >
          <div>
            {{ name }}
          </div>
        </b-checkbox>
      </span>
      <b-collapse :open="elProps.isOpen">
        <GBZFiltersList
          :filterOptions="filterOptions[name]"
          :filterSet="filterSet.nestedOptions"
          :extChecked="elProps.extChecked"
          :changes="elProps.changes"
          :defaultOptions="defaultOptions ? defaultOptions[name] : undefined"
          @nested-options-changed="
            (options) => onNestedOptionsChanged(name, options)
          "
        />
      </b-collapse>
    </li>
  </ul>
</template>

<script>
export default {
  name: "GBZFiltersList",
  props: [
    "filterOptions",
    "defaultOptions",
    "filterSet",
    "extChecked",
    "changes",
    "outerOption",
  ],
  data() {
    return {
      selectedOptions: [],
      collapsingElements: {},
      selectedOptionsIndexesRange: [],
      isDefaultOptionsSetted: false,
      selOptsToRet: {},
    };
  },
  watch: {
    filterOptions: function () {
      if (this.filterSet.nestedOptions) {
        for (let name in this.filterOptions) {
          this.$set(this.collapsingElements, name, {
            icon: "menu-right",
            isOpen: false,
            indeterminate: false,
            extChecked: 0,
            changes: 0,
          });
        }
      }
    },
    selectedOptionsIndexesRange: function (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.selectedOptions = this.selectedOptionsIndexesRange.map(
          (y) => this.filterOptions[y]
        );
      }
    },
    changes: function () {
      if (this.extChecked !== 1) {
        if (this.filterSet.nestedOptions) {
          let filOpts = Object.keys(this.filterOptions);
          for (let option in this.collapsingElements) {
            this.collapsingElements[option].extChecked = this.extChecked;
            this.collapsingElements[option].changes =
              this.collapsingElements[option].changes + 1;
          }
          if (this.extChecked === 2) {
            this.selectedOptions = filOpts;
          } else {
            this.selectedOptions = [];
          }
        } else {
          if (this.extChecked === 2) {
            this.selectedOptions = this.filterOptions;
          } else {
            this.selectedOptions = [];
          }
          this.selOptsToRet = {
            [this.filterSet.field]: this.selectedOptions,
          };
          this.$emit("nested-options-changed", this.selOptsToRet);
        }
      }
    },
  },
  methods: {
    changeNestedState(option) {
      if (this.selectedOptions.includes(option)) {
        this.collapsingElements[option].extChecked = 2;
      } else {
        this.collapsingElements[option].extChecked = 0;
      }
      this.collapsingElements[option].changes =
        this.collapsingElements[option].changes + 1;
    },
    onOptionsChanged() {
      this.selOptsToRet = {
        [this.filterSet.field]: this.selectedOptions,
      };
      this.$emit("nested-options-changed", this.selOptsToRet);
    },
    onNestedOptionsChanged(option, selOptsFromNested) {
      let nestedField = this.filterSet.nestedOptions.field;
      if (Array.isArray(selOptsFromNested[nestedField])) {
        if (selOptsFromNested[nestedField].length !== 0) {
          if (
            selOptsFromNested[nestedField].length ===
            Object.keys(this.filterOptions[option]).length
          ) {
            this.collapsingElements[option].extChecked = 2;
            this.collapsingElements[option].indeterminate = false;
          } else {
            this.collapsingElements[option].extChecked = 1;
            this.collapsingElements[option].indeterminate = true;
          }
          if (this.selOptsToRet[this.filterSet.field] === undefined) {
            this.selOptsToRet[this.filterSet.field] = {};
          }
          this.selOptsToRet[this.filterSet.field][option] =
            selOptsFromNested[nestedField];
        } else {
          this.collapsingElements[option].extChecked = 0;
          this.collapsingElements[option].indeterminate = false;
          delete this.selOptsToRet[this.filterSet.field][option];
        }
        this.selectedOptions = Object.keys(
          this.selOptsToRet[this.filterSet.field]
        );
      }
      this.$emit("nested-options-changed", this.selOptsToRet);
    },
    toggleOptions(refName) {
      let isOpen = this.collapsingElements[refName].isOpen;
      this.collapsingElements[refName].icon = !isOpen
        ? "menu-down"
        : "menu-right";
      this.collapsingElements[refName].isOpen = !isOpen;
    },
  },
  mounted() {
    if (this.defaultOptions) {
      if (this.filterSet.nestedOptions) {
        this.selectedOptions = Object.keys(this.defaultOptions);
      } else {
        if (this.defaultOptions.length !== 0) {
          this.selectedOptions = this.defaultOptions;
        } else {
          this.selectedOptions = this.filterOptions;
        }
        this.onOptionsChanged();
      }
    }
  },
};
</script>

<style>
/*.list-item {
  list-style-type: none
  padding-left: 20px;
}*/
.item-label {
  white-space: normal;
  word-wrap: break-word;
  margin: 5px 0px;
}
.filter-list-nested {
  margin-left: 20px;
}
</style>
