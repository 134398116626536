<template>
  <div>
    <b-sidebar v-model="open" :overlay="true" :fullheight="true">
      <div class="block">
        <h1 class="filter-title">Фильтры</h1>
        <ul class="filter-list">
          <li
            class="filter-item"
            v-for="filter in filterSet"
            :key="filter.field"
          >
            <b-collapse :open="false">
              <template #trigger="props">
                <div>
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-right'" />
                  {{ filter.label }}
                </div>
              </template>
              <div>
                <GBZFiltersList
                  :defaultOptions="defaultOptions[filter.field]"
                  :filterOptions="filterOptions[filter.field]"
                  :filterSet="filter"
                  @nested-options-changed="
                    (options) => onOptionsChanged(options, filter.field)
                  "
                />
              </div>
            </b-collapse>
          </li>
        </ul>
      </div>
    </b-sidebar>
    <b-button @click="open = true">Фильтры</b-button>
  </div>
</template>

<script>
import GBZFiltersList from "@/components/GBZContent/GBZFiltersList.vue";

export default {
  name: "GBZFilters",
  components: { GBZFiltersList },
  data() {
    return {
      open: false,
      selectedOptions: {},
      isDefaultOptionsSetted: false,
      //defaultOptions: {},
    };
  },
  /*watch: {
    defaultOptions: function () {
      if (Object.keys(this.defaultOptions).length === 0) {
        this.isDefaultOptionsSetted = true;
      }
    },
  },*/
  computed: {
    defaultOptions() {
      return JSON.parse(
        JSON.stringify(this.$store.getters["filter/getSelectedOptions"])
      );
    },
    filterSet() {
      return this.$store.state.filter.filterSet;
    },
    filterOptions() {
      return this.$store.state.filter.filterOptions;
    },
  },
  methods: {
    onOptionsChanged(options, field) {
      this.selectedOptions[field] = options[field];
      if (Array.isArray(this.selectedOptions[field])) {
        if (this.selectedOptions[field].length === 0) {
          delete this.selectedOptions[field];
        }
      } else {
        if (Object.keys(this.selectedOptions[field]).length === 0) {
          delete this.selectedOptions[field];
        }
      }
      if (!this.isDefaultOptionsSetted) {
        if (
          Object.keys(this.selectedOptions).length >=
            Object.keys(this.defaultOptions).length ||
          Object.keys(this.defaultOptions).length === 0
        ) {
          this.isDefaultOptionsSetted = true;
          this.$store.dispatch(
            "filter/setSelectedOptions",
            this.selectedOptions
          );
        }
      } else {
        this.$store.dispatch("filter/setSelectedOptions", this.selectedOptions);
      }
    },
  },
  created() {
    this.$store.dispatch("filter/getFilterOptions").then(() => {
      if (Object.keys(this.defaultOptions).length === 0) {
        this.isDefaultOptionsSetted = true;
      }
      //console.log(result);
    });
  },
};
</script>

<style>
.filter-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 20px;
}
/*.filter-menu-item-header {
  border: 0.25px solid;
}*/
ul.filter-list {
  list-style: none;
  /*margin-left: 20px;*/
}
li.filter-item {
  list-style: none;
  margin-left: 20px;
  padding-bottom: 3px;
}
</style>
