<template>
  <section>
    <div class="content">
      <h1>Войдите в систему для получения доступа к работе с содержимым.</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "GBZNotPermited",
};
</script>
