class Utilities {
  groupByField(data, field) {
    return data.reduce((acc, val) => {
      const rest = Object.keys(val).reduce((newObj, key) => {
        if (key !== field) {
          newObj[key] = val[key];
        }
        return newObj;
      }, {});
      if (acc[val[field]]) {
        acc[val[field]].push(rest);
      } else {
        acc[val[field]] = [rest];
      }
      return acc;
    }, {});
  }
  groupByManyFields(data, fields) {
    if (fields.length === 0) {
      return data;
    }
    let groups = {};
    groups = this.groupByField(data, fields[0]);
    let ret = {};
    for (let group in groups) {
      ret[group] = this.groupByManyFields(groups[group], fields.slice(1));
    }
    return ret;
  }
  getUniqueFieldValues(data, field) {
    let values = [];
    if (Array.isArray(data)) {
      values = data.map((record) => record[field].toString());
    } else {
      for (let group in data) {
        values = values.concat(this.getUniqueFieldValues(data[group], field));
      }
    }
    values = values.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    return values;
  }
  getUniqueGroupValues(data, depth) {
    let values = [];
    if (depth === 0) {
      values = Object.keys(data);
    } else {
      for (let group in data) {
        values = values.concat(
          this.getUniqueGroupValues(data[group], depth - 1)
        );
      }
    }
    values = values.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    return values;
  }
  getUniqueColumnsHeaders(data, columnName) {
    let values = [];
    if (Array.isArray(data)) {
      values = Object.keys(data[0][columnName]);
    } else {
      for (let group in data) {
        values = values.concat(
          this.getUniqueColumnsHeaders(data[group], columnName)
        );
      }
    }
    values = values.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    return values;
  }
  stringByTemplate(template, params) {
    let ret = template;
    for (let param in params) {
      ret = ret.replaceAll(`{${param}}`, params[param]);
    }
    return ret;
  }
}

export default new Utilities();
