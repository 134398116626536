import FiltersService from "../services/filter.service";

const moduleStates = {
  INIT: 0,
  LOADING: 1,
  ERROR: 2,
  READY: 3,
};

const defaultOptions = {
  orecomp: ["Компонент"],
  gentype: ["Коренные", "Россыпные", "Кора выветривания", "Хемогенные"],
  state_date: ["01.01.2012"],
  gbz: { Алмазы: [] },
  nas: ["Без ВНЗ", "целики"],
};

let defaultState = {
  selectedOptions: {},
  filterOptions: {},
  filterSet: [
    {
      field: "orecomp",
      label: "Руда/компонент",
      type: "checkbox",
    },
    {
      field: "state_date",
      label: "По состоянию на",
      type: "checkbox",
    },
    {
      field: "gbz",
      label: "Тип ГБЗ",
      type: "checkbox",
      nestedOptions: {
        field: "mineral",
        label: "ПИ",
        type: "checkbox",
      },
    },
    {
      field: "fo",
      label: "Федеральный округ",
      type: "checkbox",
      nestedOptions: {
        field: "subj",
        label: "Субъекты",
        type: "checkbox",
      },
    },
    {
      field: "gentype",
      label: "Ген. тип",
      type: "checkbox",
    },
    {
      field: "fund",
      label: "Фонд недр",
      type: "checkbox",
      nestedOptions: {
        field: "degree",
        label: "Степень освоения",
        type: "checkbox",
      },
    },
    {
      field: "nas",
      label: "Временно-неактивные запасы",
      type: "checkbox",
    },
  ],
  filterDependencies: {
    state_date: [
      {
        name: "year",
        transformValues(val) {
          let ret = val.map((y) => Number(y.slice(-4)) - 1);
          return ret;
        },
      },
    ],
  },
  usedFilters: {},
  changes: 0,
};

let functionsForRepresent = {
  numbers: {
    toSort: function (a, b) {
      return Number(a) > Number(b) ? 1 : Number(a) === Number(b) ? 0 : -1;
    },
    toRepresent: function (numbers) {
      if (numbers.length === 1) {
        return numbers[0];
      }
      let isInNaturalOrder = numbers.reduce(function (isNext, cur, index, arr) {
        if (index < arr.length - 1) {
          if (isNext) {
            return Number(cur) + 1 === Number(arr[index + 1]) ? true : false;
          } else {
            return false;
          }
        } else {
          return isNext;
        }
      }, true);
      if (isInNaturalOrder) {
        return "".concat(numbers[0], " - ", numbers.slice(-1));
      } else {
        return numbers.join(", ");
      }
    },
  },
  dates: {
    toSort: function (a, b) {
      let dateA_array = a.split(".");
      let dateB_array = b.split(".");
      let dateA_parsed = Date.parse(
        [dateA_array[2], dateA_array[1], dateA_array[0]].join("-")
      );
      let dateB_parsed = Date.parse(
        [dateB_array[2], dateB_array[1], dateB_array[0]].join("-")
      );
      return dateA_parsed > dateB_parsed
        ? 1
        : dateA_parsed < dateB_parsed
        ? -1
        : 0;
    },
    toRepresent: function (dates_string) {
      if (dates_string.length === 1) {
        return dates_string[0];
      }
      let dates_obj = dates_string.map((str) => {
        let d_m_y = str.split(".");
        return new Date([d_m_y[2], d_m_y[1], d_m_y[0]].join("-"));
      });
      let isInOrder = dates_obj.reduce(function (isNextYear, cur, index, arr) {
        if (index < arr.length - 1) {
          if (isNextYear) {
            return Number(cur.getFullYear()) + 1 ===
              Number(arr[index + 1].getFullYear())
              ? true
              : false;
          } else {
            return false;
          }
        } else {
          return isNextYear;
        }
      }, true);
      if (isInOrder) {
        return "".concat(dates_string[0], " - ", dates_string.slice(-1));
      } else {
        return dates_string.join(", ");
      }
    },
  },
};

let storedFilters = localStorage.getItem("filters");

if (storedFilters) {
  defaultState.selectedOptions = JSON.parse(storedFilters);
} else {
  defaultState.selectedOptions = defaultOptions;
}

export const filter = {
  namespaced: true,
  state: {
    selectedOptions: defaultState.selectedOptions,
    filterOptions: defaultState.filterOptions,
    filterSet: defaultState.filterSet,
    filterDependencies: defaultState.filterDependencies,
    usedFilters: defaultState.usedFilters,
    changes: defaultState.changes,
    filterString: null,
    moduleState: moduleStates.INIT,
  },
  actions: {
    getFilterOptions({ commit, state }) {
      commit("setModuleState", moduleStates.LOADING);
      let filters = state.filterSet.map((filter) => filter.field);
      return FiltersService.getFilterOptions(filters).then(
        (options) => {
          commit("setFilterOptions", options);
          return Promise.resolve("Filter options are setted up");
        },
        (error) => {
          commit("setModuleState", moduleStates.ERROR);
          return Promise.reject(error);
        }
      );
    },
    setSelectedOptions({ commit }, params) {
      commit("setModuleState", moduleStates.LOADING);
      commit("setSelectedOptions", params);
    },
  },
  mutations: {
    setModuleState(state, moduleState) {
      state.moduleState = moduleState;
    },
    setSelectedOptions(state, filters) {
      let transformedFilters = filters;
      let fieldsInfo = {};
      for (let field of state.filterSet) {
        fieldsInfo[field.field] = {
          isHaveNestedOptions: field.nestedOptions !== undefined,
        };
      }
      for (let field of Object.keys(transformedFilters)) {
        if (Object.keys(fieldsInfo).includes(field)) {
          if (fieldsInfo[field].isHaveNestedOptions) {
            for (let supOption of Object.keys(transformedFilters[field])) {
              let options = transformedFilters[field][supOption];
              if (
                options.length === state.filterOptions[field][supOption].length
              ) {
                transformedFilters[field][supOption] = [];
              }
            }
          }
        }
      }
      for (let field in state.filterDependencies) {
        if (transformedFilters[field] !== undefined) {
          for (let dep of state.filterDependencies[field]) {
            let fieldName = dep.name;
            let transformedValues = dep.transformValues(
              transformedFilters[field]
            );
            if (transformedValues.length !== 0) {
              transformedFilters[fieldName] = transformedValues;
            }
          }
        } else {
          for (let dep of state.filterDependencies[field]) {
            if (transformedFilters[dep.name] !== undefined) {
              delete transformedFilters[dep.name];
            }
          }
        }
      }
      state.selectedOptions = transformedFilters;
      localStorage.setItem("filters", JSON.stringify(transformedFilters));
      state.changes = state.changes + 1;
      state.moduleState = moduleStates.READY;
    },
    setFilterString(state, filterString) {
      state.filterString = filterString;
    },
    setFilterOptions(state, uniqueOptions) {
      state.filterOptions = uniqueOptions;
      state.moduleState = moduleStates.READY;
    },
    setDefaultState(state) {
      //state.defaultOptions = defaultState.defaultOptions;
      state.selectedOptions = defaultState.selectedOptions;
      state.filterOptions = defaultState.filterOptions;
      state.filterSet = defaultState.filterSet;
      state.filterDependencies = defaultState.filterDependencies;
      state.usedFilters = defaultState.usedFilters;
      state.changes = defaultState.changes;
    },
  },
  getters: {
    getSelectedOptions(state) {
      //console.log("selectedOptions in filters.module", state.selectedOptions);
      let ret = {};
      let filterNames = state.filterSet.map((el) => el.field);
      for (let field of Object.keys(state.selectedOptions)) {
        if (filterNames.includes(field)) {
          ret[field] = state.selectedOptions[field];
        }
      }
      //console.log("ret in filters.module", ret);
      return ret;
    },
    getFilterString: (state) => (filtersToRepr) => {
      let selOpts = state.selectedOptions;
      let setOfFilOpts = state.filterOptions;
      let strToRet = "";
      for (let filter of filtersToRepr) {
        if (selOpts[filter.field]) {
          let options = selOpts[filter.field];
          let optsToRepresent = "";
          if (filter.view === "numbers") {
            optsToRepresent = functionsForRepresent["numbers"].toRepresent(
              options.toSorted(functionsForRepresent["numbers"].toSort)
            );
          } else if (filter.view === "dates") {
            optsToRepresent = functionsForRepresent["dates"].toRepresent(
              options.toSorted(functionsForRepresent["dates"].toSort)
            );
          } else if (filter.view === "seq") {
            optsToRepresent = options.toSorted().join(", ");
          } else if (filter.view === "range") {
            optsToRepresent = options.join(" - ");
          } else if (filter.view === "nested") {
            let stringsToRet = [];
            let optsOfField = setOfFilOpts[filter.field];
            for (let extOpt in options) {
              if (
                options[extOpt].length === optsOfField[extOpt].length ||
                options[extOpt].length === 0
              ) {
                stringsToRet.push("".concat(extOpt));
              } else {
                stringsToRet.push(
                  "".concat(extOpt, " (", options[extOpt].join(", "), ")")
                );
              }
            }
            optsToRepresent = stringsToRet.toSorted().join(", ");
          }
          strToRet =
            strToRet + filter.label + ": " + optsToRepresent + "<br />";
        }
      }
      return strToRet;
    },
  },
};
