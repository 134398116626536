import Vue from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import setupInterceptors from "./services/setupInterceptors";

Vue.use(Buefy);

setupInterceptors(store);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
