import axiosInstance from "./api";

class UploadService {
  uploadFile(formData) {
    return axiosInstance
      .post("/api/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          console.log("error while upload");
          return Promise.reject(error);
        }
      );
  }
  getErrorReport() {
    return axiosInstance
      .get("api/get_error_report", {
        responseType: "blob",
      })
      .then(
        (response) => {
          if (response.headers["content-type"] === "application/vnd.ms-excel") {
            let filename = response.headers["content-disposition"]
              .split(";")
              .find((n) => n.includes("filename="))
              .replace("filename=", "")
              .replaceAll('"', "")
              .trim();
            let file = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            return Promise.resolve({
              status: "Report OK",
              reportFile: { filename: filename, file: file },
            });
          } else {
            return Promise.resolve({
              status: "Report not received",
              error: response.data,
            });
          }
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }
  changeUploadSessionStatus(status) {
    return axiosInstance
      .post(
        "/api/change_upload_session_status",
        { status: status },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
  }
  getLastUploadSession() {
    return axiosInstance.get("/api/get_last_upload_session").then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        //return response.data;
        return Promise.reject(error);
      }
    );
  }
}

export default new UploadService();
